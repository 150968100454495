export interface siteInteface {
  siteName: string;
  siteLogoMenu: any;
  siteLogoFooter: any;
  siteDefaultName: string;
  siteAbout?: string;
  lojas: lojaInterface[];
}

export interface lojaInterface {
  nome: string;
  telefone: string;
  link: string;
  email: string;
  endereço: string;
  hShow: string;
  hService: string;
  hPecas: string;
}

export const LOJAS_DATA: lojaInterface[] = [
  {
    nome: "Pampulha",
    telefone: "(31) 3888-000",
    link: "https://maps.app.goo.gl/AvFXgBQgUYmAWpin6",
    email: "osakapampulha@osakaveiculos.com.br",
    endereço:
      "Rua Carlos Alberto Feres 200, Liberdade, Belo Horizonte/MG - CEP: 31270-222",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 14h",
    hService: "Dias úteis: de 08h às 18h ,Sábado: 07:30h ás 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 07:30h às 12h",
  },
  {
    nome: "Contagem",
    telefone: "(31) 3303-8400",
    email: "osakacontagem@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/n4zxP5MVJSnbBQDm8",
    endereço:
      "Av. Babita Camargos, 1341 - Cidade Industrial, Contagem - MG, 32210-180",
    hShow: "Dias úteis: de 08h ás 18h ,Sábados: 08h às 14h",
    hService: "Dias úteis: de 08h ás 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
  },
  {
    nome: "BH-Carandai",
    telefone: "(31) 2129-3000",
    email: "osakabh@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/7U9fYtzXR8YinToG8",
    endereço:
      "Av. Carandaí, 874 - Santa Efigênia, Belo Horizonte - MG, 30130-060",
    hShow: "Dias úteis: 08h às 18h ,Sábados: 08h às 14h",
    hService: "Dias úteis: 07:30h às 18h ,Sábado: 07:30 às 12h",
    hPecas: "Dias úteis de 08h às 18h ,Sábado: 08h ás 12h",
  },
  {
    nome: "Sete Lagoas",
    telefone: "(31) 2106-6800",
    email: "osakasetelagoas@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/c3NPkQvRFF8hjLB66",
    endereço:
      "AV. MARECHAL CASTELO BRANCO 3409 UNIVERSITÁRIO/.Sete Lagoas-MG, 35702-134",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 13h",
    hService: "Dias úteis: 07:30h às 18h ,Sábado: 07:30h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábados: 08h às 13h",
  },
  {
    nome: "João Pinheiro",
    telefone: "(34) 2102-4900",
    link: "https://maps.app.goo.gl/K1vBRiq9oLGXRkgP7",
    email: "osakauberlandia@osakaveiculos.com.br",
    endereço:
      "Avenida João Pinheiro, 3783 - Brasil, Uberlândia - MG, 38400-714",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 13h",
    hService: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "João Naves",
    telefone: "(34) 2101-9600",
    link: "https://maps.app.goo.gl/RhwF1pA783GWCr9Q9",
    email: "osakauberlandiajn@aguiabranca.com.br",
    endereço: "Av. João Naves de Ávila, 5450 - MG, 38408-663",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h ás 13h",
    hService: "Dias úteis: de 07:30h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Uberaba",
    telefone: "(34) 2104 - 4600",
    email: "osakauberaba@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/s9C1NvWbjPdJuuq86",
    endereço:
      "Avenida Nenê Sabino, n° 3209, Santos Dumont - Uberaba/MG - CEP 38.050-501",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 13h",
    hService: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Patos de Minas",
    telefone: "(34) 2106-8800",
    email: "osakapatosdeminas@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/izFcdmtThGpc54746",
    endereço:
      "Av.: Juscelino Kubitschek, 1050, bairro Cidade Nova - Patos de Minas MG",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 13h",
    hService: "Dias úteis: 07:30h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Ipatinga",
    telefone: "(31) 3829-7000",
    email: "osakaipatinga@kurumaveiculos.com.br",
    link: "https://maps.app.goo.gl/G8DbEDx5HdUYRuxn7",
    endereço:
      "Avenida Pedro Linhares Gomes, 2510 - Horto, Ipatinga - MG, 35162-038",
    hShow: "Dias úteis: de 07:30h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: de 07:30h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 07:30h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Governador Valadares",
    telefone: "(33) 2101-7000",
    link: "https://maps.app.goo.gl/cBPotXVkny4XzNDR6",
    email: "osakavaladares@osakaveiculos.com.br",
    endereço:
      "Avenida JK,1700, Bairro Vila Bretas, Governador Valadares, 35030-210",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: 07:30h às 17:30h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
  },
  {
    nome: "Teófilo Otoni",
    telefone: "(33) 3536-000",
    email: "osakateofilootoni@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/rpeqb4XCqEHYe9Pt7",
    endereço: "Avenida Alfredo Sá ,4000, Bairro São Cristóvão, Teófilo Otoni",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: 07:30h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: 07:30h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "João Monlevade",
    telefone: "(31) 3808-1050",
    link: "https://maps.app.goo.gl/FZC3BaimihgHwbv77",
    email: "oskjmonlevade@osakaveiculos.com.br",
    endereço:
      "Avenda Wilson Alvarenga,470, São João, João Monlevade -MG, 35930-001",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: 08h às18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: 08h às18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Manhuaçu",
    telefone: "(33) 3339-1700",
    email: "osakamanhuacu@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/ZtF7q8i6ZF7Q4GBEA",
    endereço:
      "Av. Dr. Jorge Hannas, 3291, Bom Jardim, Manhuaçu, MG, CEP -36.906-360",
    hShow: "Dias úteis: 08h às 18h ,Sábados:08h às 12h",
    hService: "Dias úteis: 07:30h às 18h ,Sábado: 07:30h às 12h",
    hPecas: "Dias úteis: 07:30h às 18h ,Sábado: 07:30h às 12h",
  },
  {
    nome: "Juiz de Fora",
    telefone: "(32) 2102-5656",
    email: "osaka@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/grnU1iVpFf5hxnWaA",
    endereço:
      "Av. Deusdedith Salgado, 4987 - Teixeiras, Juiz de Fora - MG, 36033-000",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: 07:30h às 17:30h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Muriaé",
    telefone: "(32) 3729- 6200",
    email: "Osakamuriae@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/CeNdqyZEm7iANn4b7",
    endereço:
      "Avenida Presidente Castelo Branco, n°02, Bairro Gávea, Muriaé MG, CEP: 36889-034",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: 08h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Ubá",
    telefone: "(32) 4000-1074",
    email: "osakaubá@osakaveiculos.com.br",
    link: "https://maps.app.goo.gl/uYfmNqhJKrjwHd8V6",
    endereço:
      "Avenida Quintino Poggiali, n°880 - Waldemar de Castro, Ubá MG, CEP:36505140",
    hShow: "Dias úteis: 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: 08h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: 08h às 18h ,Sábados: 08h às 12h",
  },
  {
    nome: "Barbacena",
    telefone: "(32) 3339-9250",
    email: "osaka@osakaveiculos.com.br",
    link: "https://g.co/kgs/TZmzu4X",
    endereço:
      "Av. Governador Bias Fortes, 455, Bairro Pontilhão, CEP: 36204-168, Barbacena - MG",
    hShow: "Dias úteis: 08h às 18h ,Sábados: 08h ás 12h",
    hService: "Dias úteis: 08h às 18h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: 08h às 18h ,Sábado: 08h às 12h",
  },
];

export const SITES_CONFIG = {
  OSAKA: {
    siteName: "OSAKA",
    siteDefaultName: "Osaka",
    lojas: LOJAS_DATA,
    pathname: typeof window !== "undefined" ? window.location.pathname : "",
  },
} as const;

export const actualSite = () => {
  const siteName = process.env
    .NEXT_PUBLIC_SITE_NAME as keyof typeof SITES_CONFIG;
  return SITES_CONFIG[siteName] || SITES_CONFIG.OSAKA;
};

export function formatCarModel(model: string): string {
  return model
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function formatName(model: string): string {
  return model
    .toLocaleLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const BRAND_NAMES = {
  SEMINOVOS_SITE_URL: "https://www.osakaseminovos.com.br",
  SEMINOVOS_CERTIFICADO_URL:
    "https://www.osakaseminovos.com.br/veiculos?certificado=true&ano=2018,2025&preco=4390000,42499000&km=101,81193&entrada=12749700&ordenacao=1",
  CHAT_INSTANCE_ID: "SITE_OSAKA",
  CHAT_API_KEY: "E45D12F89A7346B1BC9E782F3A2D54CBE45D12F89A7346B1",
  POS_NUMBER: "553130032626",
  NORMAL_NUMBER: "5531999999156",
  STATE: "Minas Gerais",
  STATE_RED: "em Minas Gerais",
  SUB_BRAND: "10",
  DEFAULT: "Osaka",
  UPPERCASE: "OSAKA",
  LOWERCASE: "osaka",
  ALL_CAPS: "OSAKA",
  LOWECASE_PLAIN: "osaka",
  LOWERCASE_PLAIN: "osaka",
  CAMELCASE: "Osaka",
  DISPLAY: "Osaka Veículos",
  LEGAL: "Osaka Veículos Toyota",
  SHORT: "OSK",
  URL: "osakaveiculos",
  META_TITLE: "Osaka Toyota",
  EMAIL_DOMAIN: "@osakaveiculos.com.br",
  NUMERO_POS_VENDA: "3130032626",
  NUMERO_POS_VENDA_TRATADO: "(31) 3003-2626",
  siteLogoMenu:
    "https://sablobsitestoyotaprd.blob.core.windows.net/images/logo_menu_osaka.svg",
  ABOUT: `A Osaka Toyota faz parte do Grupo Águia Branca, um dos maiores conglomerados de transporte e logística do Brasil, reconhecido pela excelência em serviços e produtos automotivos. Com uma presença sólida em Minas Gerais, estamos presentes em 17 lojas, estrategicamente distribuídas nas cidades de Barbacena, Belo Horizonte, Contagem, Governador Valadares, Ipatinga, João Monlevade, Juiz de Fora, Manhuaçu, Muriaé, Pampulha, Patos de Minas, Sete Lagoas, Teófilo Otoni, Ubá, Uberaba, Uberlândia (João Pinheiro e João Naves).

Desde 1946, o Grupo Águia Branca é sinônimo de inovação, qualidade e confiança, operando em setores como transporte de passageiros, logística de cargas, aluguel de veículos e terceirização de frotas, além de ser o maior representante da Toyota na América Latina e o maior grupo de concessionárias Lexus no Brasil.

As concessionárias Toyota do Grupo Águia Branca seguem rigorosamente os padrões globais da montadora, com o propósito de proporcionar aos nossos clientes uma experiência de compra e posse inigualável no mercado automotivo. Há mais de 78 anos, o Grupo Águia Branca tem sido sinônimo de excelência, inovação e expansão contínua.

Com presença nacional, o grupo se destaca em setores estratégicos como transporte de passageiros, logística e comércio de veículos. Somos o maior representante da Toyota na América Latina e o maior grupo de concessionárias Lexus no Brasil. Contamos com mais de 100 concessionárias espalhadas pelo país e a força de mais de 20 mil colaboradores, com certificação GPTW, movidos pela paixão de oferecer o melhor em todas as nossas operações.

Como representantes da Toyota em três estados — Minas Gerais,Distrito Federal e Espírito Santo —, temos orgulho de honrar diariamente o compromisso com a qualidade e confiabilidade, valores que definem a Toyota mundialmente. Nossa jornada é impulsionada pela paixão em conectar pessoas e oportunidades, sempre oferecendo produtos e serviços de excelência.`,
} as const;

// Tipo utilitário para acessar as chaves
export type BrandNameKeys = keyof typeof BRAND_NAMES;
